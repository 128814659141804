export const adminUserEmails = [
  `hajek.raven@gmail.com`,
  `lukas.polak@investujdopole.cz`,
  `marketa.polakova@investujdopole.cz`,
  `michaela.fischerova@investujdopole.cz`,
  `romana.kubasova@investujdopole.cz`,
  `frantisek.stastnik@investujdopole.cz`,
] as const;

export const isAdmin = (email: string) => adminUserEmails.includes(email as any);

const adminUrls = [`/export`, `/procedures`, `/inheritance`, `/settings`];

export const isAllowedToVisit = (user: string, url: string) => {
  if (isAdmin(user)) {
    return true;
  }
  return !adminUrls.includes(url);
};
